import DataTable from 'react-data-table-component'
import { useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import InputSelect from './InputSelect/InputSelect'


const DataTableComponent = ({
	title,
	description,
	columns,
	data,
	perPage,
	setPerPage,
	getData = () => {},
	totalRows,
	loading,
	sercheable=true,
	model,
	customStyles = {},
	filters = null,
	isFiltered = false,
	filterData = null,
	noPagination = false,
	expandableRows = false,
	expandableRowsComponent = null,
	expandOnRowClicked = false,
	expandableRowsHideExpander = false,
	fixedHeader = true,
	defaultSortFieldId = -1,
	fixedHeaderScrollHeight = '500px',
	selectConfig = null,
	RightSideComponent = null
}) => {
	const navigate = useNavigate()
	const [currentRow, setCurrentRow] = useState(null)

	const localStyles = {
		rows: {
			style: {
				minHeight: customStyles.rowHeight ? customStyles.rowHeight : 38, // override the row height
			},
		},
		headCells: {
			style: {
				paddingLeft: '0', // override the cell padding for head cells
				paddingRight: '0',
			},
		},
		cells: {
			style: {
				paddingLeft: '0', // override the cell padding for data cells
				paddingRight: '0',
			},
		},
	}
	useEffect(() => {
		init()

		// eslint-disable-next-line
	}, [])

	const init = () => {
		if (!['booking', 'bookingLine', 'event'].includes(model))getData(1, perPage)
	}

	const handlePageChange = (page) => {
		getData(page, perPage)
	}

	const handleRowsPerPageChange = (newPerPage, page) => {
		getData(page, newPerPage)
		setPerPage(newPerPage)
	}
	//check
	const handleRowClicked = (row) => {
		if (model==='metrics') return
		if (model !== '' && model !== "cashier") {
			const editUrl = `/${model}/edit/${row.id}`
			window.location.href = editUrl
			//navigate(editUrl) 
		}
		if (expandableRows) {
			setCurrentRow(row)
		}
	}

	const handleSort = (column, sortDirection) => {
		getData(1, perPage, sortDirection, column.field, null, filters)
	}

	let conditionalRowStyles = []
	if (model == 'origin') {
		conditionalRowStyles = [
			{
			  when: row => row.parent == '0',
			  style: {
				backgroundColor: '#ffeac7',
			  },
			},
			{
				when: row => row.parent != '0',
				style: {
				  backgroundColor: '#fff7ea',
				},
			},
		];
	} else if (model==='metrics') {
		// conditionalRowStyles = [
		// 	{
		// 	  when: row => Object.values(row).includes('TOTAL'),
		// 	  style: {
		// 		pointerEvents: 'none',
		// 	  },
		// 	}
		// ];
	}

	return (
		<>
			<div className="d-sm-flex align-items-center justify-content-between my-3 datatable-block">
				{title != '' && 
						<div className={`mb-2 ${selectConfig || RightSideComponent ? "header-datatable" :""}`}>
							<div>
								<h5 className="mb-0">{title}</h5>
								{selectConfig ? (<InputSelect {...selectConfig} />) :
								 RightSideComponent ? (<RightSideComponent />) :
								 null
								}
							</div>
							{ description && (
								<div><p className="card-subtitle mt-2">{description}</p></div>
							)}
						</div>
				}
				{isFiltered && (
					<div class="form-group">
						<form className="position-relative">
							<input
								type="text"
								className="form-control filter-type search-chat py-2 ps-5"
								placeholder="Filtrar por tipo"
								onChange={(e) => filterData(e.target.value)}
							/>
							<i className="ti ti-filter position-absolute top-50 start-0 translate-middle-y fs-6 text-dark ms-3"></i>
						</form>
					</div>
				)}

				{data && !noPagination && sercheable && (
					<form className="position-relative" onSubmit={(e) => {
						e.preventDefault()
						if (!e.target[0].value) return
						getData(1, perPage, undefined, undefined, e.target[0].value, null, true)
					}}>
						<input
							type="text"
							className="form-control search-chat py-2 ps-5"
							id="text-srh"
							placeholder="Buscar..."
						/>
						<i className="ti ti-search position-absolute top-50 start-0 translate-middle-y fs-6 text-dark ms-3"></i>
					</form>
				)}
			</div>
			<DataTable
				columns={columns}
				data={data}
				fixedHeader={fixedHeader}
				fixedHeaderScrollHeight={fixedHeaderScrollHeight}
				progressPending={loading}
				pagination={!noPagination}
				paginationServer={!noPagination}
				paginationTotalRows={totalRows}
				paginationRowsPerPageOptions={[50, 100, 200, 500]}
				onChangeRowsPerPage={handleRowsPerPageChange}
				onChangePage={handlePageChange}
				onRowClicked={handleRowClicked}
				onSort={handleSort}
				customStyles={localStyles}
				conditionalRowStyles={conditionalRowStyles}
				expandableRows={expandableRows}
				expandableRowsComponent={expandableRowsComponent}
				expandOnRowClicked={expandOnRowClicked}
				expandableRowsHideExpander={expandableRowsHideExpander}
				onRowExpandToggled={(bool, row) => setCurrentRow(row)}
				expandableRowExpanded={(row) => (row === currentRow)}
			/>
		</>
	)
}

export default DataTableComponent
