import { useEffect, useState } from 'react'
import { getBookingLogs } from '../services/apiService'
import useApp from '../utils/useApp'



const BookingLogs = ({booking}) => {

    const { state:{ user }} = useApp()
    const [logs, setLogs] = useState([])

    useEffect(() => {
        if (!booking) return
        getBookingLogs(booking.venueId,  booking.id)
        .then((response) => {
            if(response.result === 'ok') {
                setLogs(response.log)
            }
        })
    }, [booking])

    
    return (
        <div className=" mb-4 px-5 py-4">
            <div className="row">
                <div className="col-12">
                    {logs.length === 0 ?
                        <h3 className='card-title fs-6 fw-semibold mb-3'>Loading Booking Logs</h3>
                    : 
                        <>
                        <h3 className='card-title fs-6 fw-semibold mb-3'>Logs Booking</h3>
                        <div className="logs-wrapper " dangerouslySetInnerHTML={{ __html: logs }} />
                        </>
                    }
                    
                </div>
            </div>
        </div>
    )

}

export default BookingLogs