import DataTableComponent from '../components/DataTableComponent'
import {
	columnsBookings,
	columnsBookingsMetrics,
	filtersRow2,
	filtersRow3,
	filtersRow4,
	defaultFilters,
	defaultUserPreferences,
	metricsOpts,
	getStayLength,
} from '../utils/bookings-data'
import React, { useEffect, useState, useCallback } from 'react'
import { getDataApi, downloadApi } from '../services/apiService'
import useApp from '../utils/useApp'
import WeekData from '../parts/WeekData.js'
import SalesDensity from '../components/SalesDensity'
import Filters from '../components/filters/filters.js'

let anchor = document.createElement('a')
let hasRendered = false

const lt = (offset, ...params) => new Date(new Date(...params).getTime() - (offset ? new Date(...params).getTimezoneOffset() * 60000 : 0)).toISOString()

const BookingPage = () => {

	const {
		state: { user },
		actions: { updateUser },
	} = useApp()

	const [dataBooking, setDataBooking] = useState([])
	const [dataBookingHours, setDataBookingHours] = useState([])
	const [totalRows, setTotalRows] = useState(0)
	const [perPage, setPerPage] = useState(100)
	const [groupMetrics, setGroupMetrics] = useState('shiftId')
	const [metrics, setMetrics] = useState([])
	const [colMetrics, setColMetrics] = useState(columnsBookingsMetrics)
	const [[loTable, loMetrics, loGraphic], setLoading] = useState([1, 1, 0])
	const [metricsOptions, setMetricsOptions] = useState(metricsOpts)
	const [filters, setFilters] = useState(defaultFilters)
	const [testUserPreferences, setTestUserPreferences] = useState(defaultUserPreferences)

	//filter rows functions
	const handleRefresh = () => {
		setLoading((prev) => {
			let newLoadingState = [...prev]
			newLoadingState[0] = 1
			newLoadingState[1] = 1
			newLoadingState[2] = 1
			return newLoadingState
		})

		getBookingsData(1, perPage, 'desc', 'id', null, null)
		getMetrics()
		getMetrics({ target: { value: 'salesDensity' } })
	}

	const downloadXls = async () => {
		const format = 'xls'
		downloadApi(user.lastVenue, 'booking', 0, 0, null, null, null, null, null, filters, format)
			.then((response) => response.blob())
			.then((blobby) => {
				let objectUrl = window.URL.createObjectURL(blobby)
				anchor.href = objectUrl
				anchor.download = 'export.xlsx'
				anchor.click()
				window.URL.revokeObjectURL(objectUrl)
			})
	}

	//metrics function functions
	const computeMetricsFilters = (gm) => {
		//modify the needed filters based on gm (group metrics)
		const today = new Date()
		let f = JSON.parse(JSON.stringify(filters))
		f.metrics = gm === 'salesDensity' ? 'salesHour' : gm

		if (gm === 'eventDay' && f.date[0] === '') {
			f.date[0] = new Date(Date.UTC(today.getFullYear(), today.getMonth(), 1)).toISOString().substring(0, 10)
			f.date[1] = new Date(Date.UTC(today.getFullYear(), today.getMonth() + 1, 0)).toISOString().substring(0, 10)
		}
		if (gm === 'creationDay' && f.createdAt[0] === '') {
			f.createdAt[0] = new Date(Date.UTC(today.getFullYear(), today.getMonth(), 1)).toISOString().substring(0, 10)
			f.createdAt[1] = new Date(Date.UTC(today.getFullYear(), today.getMonth() + 1, 0))
				.toISOString()
				.substring(0, 10)
		}

		return f
	}

	const getNeedsBasedOnGm = (gm) =>
		//add more cases if needed [totals Obj, reduce Obj, [array with both object keys]]
		//add [gm]: booking[gm] prop to reduce object in reduce loop
		gm === 'zone'
			? [
					{ tickets: 0, bookings: 0, events: 0, percentage: 0, average: 0, amount: 0, zone: 'TOTAL' },
					{ tickets: 0, bookings: 0, events: 0, percentage: 0, average: 0, amount: 0 },
					[
						//['acc_key / totals_key', 'booking_key']
						['tickets', 'tickets'],
						['bookings', 'bookings'],
						['amount', 'price'],
						['events', 'events'],
						['percentage', 'percentage'],
						['average', 'average'],
					],
			  ]
			: gm === 'journey' ? [
				{ forms: 0, visits: 0, visitsCtr: 0, formsCtr: 0, tickets: 0, salesAmount: 0, bookings: 0, average: 0, amount: 0, za: 0, za2: 0, zb: 0, zc: 0, zv: 0, unasigned: 0, journey: 'TOTAL' },
				{ forms: 0, visits: 0, visitsCtr: 0, formsCtr: 0, tickets: 0, salesAmount: 0, bookings: 0, average: 0, amount: 0, za: 0, za2: 0, zb: 0, zc: 0, zv: 0, unasigned: 0 },
				[
					['tickets', 'tickets'],
					['amount', 'price'],
					['za', 'za'],
					['za2', 'za2'],
					['zb', 'zb'],
					['zc', 'zc'],
					['zv', 'zv'],
					['unasigned', 'unasigned'],
					['average', 'average'],
					['bookings', 'bookings'],
					['salesAmount', 'price'],
					['visits', 'visits'],
					['forms', 'forms'],
				],
			] : gm === 'sellerPerformance' ? [
				{ tickets: 0, salesAmount: 0, bookings: 0, average: 0, amount: 0, za: 0, za2: 0, zb: 0, zc: 0, zv: 0, unasigned: 0, sellerId: 'TOTAL' },
				{ tickets: 0, salesAmount: 0, bookings: 0, average: 0, amount: 0, za: 0, za2: 0, zb: 0, zc: 0, zv: 0, unasigned: 0 },
				[
					['tickets', 'tickets'],
					['amount', 'price'],
					['za', 'za'],
					['za2', 'za2'],
					['zb', 'zb'],
					['zc', 'zc'],
					['zv', 'zv'],
					['unasigned', 'unasigned'],
					['average', 'average'],
					['bookings', 'bookings'],
					['salesAmount', 'price'],
				],
			  ]
			: //default
			  [
					{ pax: 0, price: 0, salesAmount: 0, aftersalesAmount: 0, count: 0, [gm]: 'TOTAL' },
					{ pax: 0, price: 0, salesAmount: 0, aftersalesAmount: 0, count: 0 },
					[
						['pax', 'pax'],
						['price', 'price'],
						['salesAmount', 'salesAmount'],
						['aftersalesAmount', 'aftersalesAmount'],
						['count', 'bookings'],
					],
			  ]

	const computeMetricsResponse = (gm, data) => {
		if (gm === 'salesDensity') {
			setDataBookingHours(data.map((b) => ({ ...b, salesHour: lt(true, b.createdAt).substring(11, 13) })))
			return null //if null, the getMetrics function stops
		}
		if (gm === 'salesHour') {
			return data.map((b) => ({ ...b, salesHour: lt(true, b.createdAt).substring(11, 13) }))
		}
		if (gm === 'creationDay') {
			return data.map((b) => ({ ...b, creationDay: lt(true, b.createdAt).split('T')[0] }))
		}
		if (gm === 'eventDay') {
			return data.map((b) => ({ ...b, eventDay: lt(false, b.date).split('T')[0] }))
		}
		return data
	}

	const getMetrics = useCallback(async (e = { target: { value: groupMetrics } }) => {
		setLoading((prev) => {
			let newState = [...prev]
			newState[1] = 1
			return newState
		})

		const gm = e.target.value
		if (gm !== 'salesDensity') setGroupMetrics(gm)

		let metricsFilters = computeMetricsFilters(gm)

		const datesDiff = getStayLength(gm === 'eventDay' ? metricsFilters.date : metricsFilters.createdAt) + 1

		const result = await getDataApi(
			metricsFilters.venueId,
			'booking',
			0,
			1000000,
			null,
			null,
			'id',
			'ASC',
			null,
			metricsFilters
		)

		let metrics = computeMetricsResponse(gm, result.data)
		if (metrics === null) return

		const [totals, defaultAcc, keys] = getNeedsBasedOnGm(gm)

		let metricsArr = metrics.reduce((acc, booking) => {
			let currentVal = ''
			let weekVal = ''

			if ((gm === 'creationDay' || gm === 'eventDay') && datesDiff >= 14) {
				//INITIALIZE BASIC METRIºCS OBJECT + WEEK DATA
				let thisRowDate = new Date(booking[gm])
				let a = new Date(Date.UTC(thisRowDate.getFullYear(), thisRowDate.getMonth(), 1)).getDay()
				let b = thisRowDate.getDate()
				let c = a > 0 ? a - 1 : 6
				let d = b + c
				let e = Math.ceil(d / 7)

				currentVal = `${e}-${thisRowDate
					.toLocaleDateString('es', { month: 'short', year: 'numeric' })
					.replace(/ /g, '-')}`
				weekVal = `${thisRowDate
					.toLocaleDateString('es', { day: '2-digit', month: 'short', year: 'numeric' })
					.replace(/ /g, '-')}`
				acc[currentVal] = acc[currentVal] || {
					...defaultAcc,
					weekData: {},
					[gm]: `${e}-${thisRowDate.getTime()}`,
				}

				acc[currentVal].weekData[weekVal] = acc[currentVal].weekData[weekVal] || {
					...defaultAcc,
					[gm]: `${thisRowDate.getTime()}`,
				}
			} else if ((gm === 'creationDay' || gm === 'eventDay') && datesDiff < 14) {
				let thisRowDate = new Date(booking[gm])
				currentVal = `${thisRowDate
					.toLocaleDateString('es', { day: '2-digit', month: 'short', year: 'numeric' })
					.replace(/ /g, '-')}`
				acc[currentVal] = acc[currentVal] || {
					...defaultAcc,
					[gm]: `${thisRowDate.getTime()}`
				}
			} else if (gm === 'sellerPerformance') {
				//the same as default case but we don't need gm, we need sellerId
				currentVal = booking.sellerId
				acc[currentVal] = acc[currentVal] || { ...defaultAcc, sellerId: booking.sellerId }
			} else {
				//INITIALIZE BASIC METRICS OBJ
				currentVal = booking[gm]
				acc[currentVal] = acc[currentVal] || { ...defaultAcc, [gm]: booking[gm] }
			}

			keys.forEach((keys) => {
				//special case, only in test a b and key = hits
				if (gm === 'journey' && (keys[0] === 'visits' || keys[0] === 'forms')) {
					const trackingsRow = result.trackingsData[keys[0]].find(sum => sum.journey === acc[currentVal].journey)
					if (trackingsRow) {

						acc[currentVal][keys[0]] += trackingsRow.hits
						totals[keys[0]] += trackingsRow.hits
						const ctrKey = keys[0] === 'visits' ? 'visitsCtr' : 'formsCtr'
						acc[currentVal][ctrKey] = (acc[currentVal].amount / acc[currentVal][keys[0]])
						totals[ctrKey] = (totals.amount / totals[keys[0]])

					}
					return
				}

				//rest of the cases
				const toAdd = Number(booking[keys[1]])

				//normal sum
				acc[currentVal][keys[0]] += toAdd
				//sum of week subindexes in creationDay & eventDay
				if (weekVal !== '') acc[currentVal].weekData[weekVal][keys[0]] += toAdd
				//sum of totals
				if (keys[0] === 'average') {
					totals[keys[0]] = totals['amount'] / totals['tickets']
				}
				else if (keys[0] === 'percentage')
					totals[keys[0]] = Math.round(
						(100 *
							(totals['tickets'] -
								(acc['sin zona asignada'] ? acc['sin zona asignada']['tickets'] : 0))) /
							(result.capacity * booking['events'])
					)
				else if (!(keys[0] === 'events' && totals[keys[0]] > 0)) totals[keys[0]] += toAdd
			})

			return acc
		}, {})
		metricsArr['Total'] = totals
		metricsArr = Object.values(metricsArr)
		setColMetrics(
			columnsBookingsMetrics.filter((col) => {
				return (
					col.field === gm ||
					(gm === 'zone'
						? //array with this group metrics columns
						  ['tickets', 'bookings', 'amount', 'average', 'events', 'percentage'].includes(col.field)

						: gm === 'sellerPerformance'
						? ['tickets', 'zonesGraphic', 'average', 'amount', 'sellerId', 'za', 'zv'].includes(col.field)

						: gm === 'journey'
						? ['tickets', 'bookings', 'zonesGraphic', 'average', 'salesAmount', 'journey', 'zv', 'za', 'zb', 'zc', 'visitsCtr', 'visits', 'formsCtr', 'forms'].includes(col.field)

						: //default columns
						  ['price', 'pax', 'count', 'salesAmount', 'aftersalesAmount'].includes(col.field))
				)
			})
		)

		setMetrics(metricsArr)
		setLoading((prev) => {
			let newLoadingState = [...prev]
			newLoadingState[1] = 0
			return newLoadingState
		})
	}, [filters])

	const getBookingsData = useCallback((
		page,
		limit = perPage,
		sortDirection = 'desc',
		sortField = 'id',
		searchTerm = null,
		format = null,
		omitFilters = null,
	) => {
		const offset = Math.max(0, page - 1) * limit
		console.log(filters)
		getDataApi(
			filters.venueId,
			'booking',
			offset,
			limit,
			null,
			null,
			sortField,
			sortDirection,
			searchTerm,
			omitFilters ? null : filters,
			format
		).then((result) => {
			const BookingsDataResponse = Object.values(result.data).map((booking) => {
				return {
					id: booking.id,
					pax: booking.pax,
					locators: booking.locator,
					status: booking.status,
					paymentStatus: booking.paymentStatus,
					filter: booking.filter,
					date: booking.date,
					venueId: booking.venueId,
					venueFrom: booking.venueFrom,
					name: booking.name,
					email: booking.email,
					phone: booking.phone,
					country: booking.country,
					origin: booking.origin,
					tags: booking.tags,
					price: booking.price,
					salesAmount: booking.salesAmount,
					aftersalesAmount: booking.aftersalesAmount,
					access: JSON.stringify(booking.access),
					tables: JSON.stringify(booking.tables),
					shiftId: booking.shiftId,
					eventId: booking.eventId,
					sellerId: booking.sellerId,
					deviceId: booking.deviceId,
					createdAt: booking.createdAt,
					updatedAt: booking.updatedAt,
					journey: booking.journey,
					notes: booking.notes,
					icons: JSON.stringify(booking.icons),
					meals: booking.meals,
					children: booking.children,
					salesHour: booking.createdAt.substring(11, 13),
				}
			})
			setDataBooking(BookingsDataResponse)
			setTotalRows(result.count)
			setLoading((prev) => {
				let newLoadingState = [...prev]
				newLoadingState[0] = 0
				return newLoadingState
			})
		})
	},[filters])

	const config = {
		page: 'bookingsPage',
		localStorageItem: 'booking-filters',
		filtersInterface: defaultFilters,
		preferencesInterface: { autoFilters: 0, salesDensity: 0 },
		rows: [
			[
				{ role: 'layout', inner: 'VENTAS' },
				[
					{ key: 'update', action: handleRefresh },
					{ key: 'download_xls', action: downloadXls },
				],
			],
			filtersRow2,
			filtersRow3,
			filtersRow4,
		],
	}

	useEffect(() => {
		if (hasRendered) handleRefresh()
		else hasRendered = true
	}, [filters])

	return (
		<>
			<div className="card">
				<div className="card-body">
					<Filters
						config={config}
						onFilterChange={(e) => {
							setFilters(e)
							console.log('ok')
						}}
						onPreferenceChange={(e) => setTestUserPreferences(e)}
					/>
					<hr />
					<SalesDensity
						data={dataBookingHours}
						show={testUserPreferences.salesDensity === 1}
						loading={loGraphic === 1}
						onLoad={() =>
							setLoading((prev) => {
								let newLoadingState = [...prev]
								newLoadingState[2] = 0
								return newLoadingState
							})
						}
						propsToConcat={[
							{ iconClassName: '', propName: 'price', colorizedByName: 'Ganancias', visibleText: '€' },
							{
								iconClassName: 'ti ti-user',
								propName: 'pax',
								colorizedByName: 'Personas',
								visibleText: '',
							},
							{
								iconClassName: 'ti ti-ticket',
								propName: 'bookings',
								colorizedByName: 'Ventas',
								visibleText: '',
							},
						]}
					/>

					<div>
						<div className="spinner-limits">
							{/* <SpinnerComponent display="block" loading={loadingMetrics} /> */}
							<DataTableComponent
								title={`Métricas sumarizadas - ${metricsOptions.find((op) => op.value===groupMetrics).name}`}
								columns={colMetrics}
								getData={() => {}}
								setPerPage={() => {}}
								data={metrics}
								model="metrics"
								noPagination={true}
								loading={loMetrics === 1}
								selectConfig={{
									name: 'metrics',
									selectId: 'slct-metrics',
									options: metricsOptions,
									setOptions: setMetricsOptions,
									onInput: getMetrics,
									showCurrentValue: true,
									selectAllOption: false,
									defaultValue: 'shiftId',
									autoHide: true,
									onlyOne: true,
								}}
								expandOnRowClicked={
									groupMetrics === 'creationDay' || groupMetrics === 'eventDay' ? true : false
								}
								expandableRowsHideExpander={
									groupMetrics === 'creationDay' || groupMetrics === 'eventDay' ? true : false
								}
								expandableRows={
									groupMetrics === 'creationDay' || groupMetrics === 'eventDay' ? true : false
								}
								expandableRowsComponent={({ data }) =>
									(groupMetrics === 'creationDay' || groupMetrics === 'eventDay') &&
									data.weekData &&
									data[groupMetrics].includes('-') ? (
										<WeekData formData={Object.values(data.weekData)} gm={groupMetrics} />
									) : null
								}
							/>
						</div>
					</div>
					<hr />
					<DataTableComponent
						title="Listado detalle de ventas"
						description="Tabla pormenorizada de las reservas realizadas"
						columns={columnsBookings}
						data={dataBooking}
						setPerPage={setPerPage}
						loading={loTable === 1}
						getData={getBookingsData}
						totalRows={totalRows}
						model="booking"
						filters={filters}
					/>
				</div>
			</div>
		</>
	)
}
export default BookingPage
